



















import Vue from 'vue';
import { HomeStatusEnum } from '@/scripts/store/modules/home/types';

export enum StepStatus {
  /* eslint-disable no-unused-vars */
  COMPLETED = 'COMPLETED',
  INITIAL = 'INITIAL',
  RECOMMENDED = 'RECOMMENDED',
  STARTED = 'STARTED',
  /* eslint-enable no-unused-vars */
}

export interface IStep {
  text: String,
  status: String,
}

export default Vue.extend({
  name: 'step-icon',
  props: {
    step: {
      type: Object as () => IStep,
      required: true,
    },
    large: {
      type: Boolean,
      required: false,
    },
    showText: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      status: HomeStatusEnum,
    };
  },
  computed: {
    size(): String {
      return this.large ? (this.$vuetify.breakpoint.xsOnly ? '30px' : '50px') : '25px';
    },
  },
});
