var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        icon: "",
        flat: "",
        color: "primary",
        loading: _vm.loading,
        small: _vm.$vuetify.breakpoint.xsOnly
      },
      on: { click: _vm.toggle },
      scopedSlots: _vm._u([
        {
          key: "loader",
          fn: function() {
            return [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-icon", { class: { activated: _vm.isFavorite } }, [
        _vm._v("$vuetify.icons.starling_favorite")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }