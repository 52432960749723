var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "align-center": "", "justify-center": "" } },
    [
      _c("audio", {
        ref: "player",
        attrs: { src: _vm.audioURL, preload: "metadata" }
      }),
      _c(
        "v-flex",
        { attrs: { xs11: "", lg9: "", xl6: "" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      ref: "container",
                      staticClass: "audio-player",
                      attrs: {
                        xs12: "",
                        "pa-2": "",
                        "px-1": _vm.$vuetify.breakpoint.xsOnly
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            "align-center": "",
                            "gap-xs-1": "",
                            "gap-sm-2": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              !_vm.ready
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      color: "primary",
                                      width: "3",
                                      indeterminate: ""
                                    }
                                  })
                                : _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-0",
                                      attrs: {
                                        flat: "",
                                        icon: "",
                                        color: "primary"
                                      },
                                      on: { click: _vm.togglePlay }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.actionIcon))
                                      ])
                                    ],
                                    1
                                  )
                            ],
                            1
                          ),
                          _c("v-flex", { attrs: { shrink: "" } }, [
                            _c(
                              "span",
                              { staticStyle: { "white-space": "nowrap" } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.currentTime + " / " + _vm.duration
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ]),
                          _c(
                            "v-flex",
                            [
                              _c("v-slider", {
                                staticClass: "ma-0 pl-1",
                                attrs: {
                                  max: _vm.sliderMax,
                                  "hide-details": ""
                                },
                                on: { change: _vm.handleProgress },
                                model: {
                                  value: _vm.seekSlider,
                                  callback: function($$v) {
                                    _vm.seekSlider = $$v
                                  },
                                  expression: "seekSlider"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-flex", { attrs: { shrink: "" } }, [
                            _c(
                              "div",
                              {
                                class: {
                                  "volume-control":
                                    _vm.$vuetify.breakpoint.mdAndUp && _vm.hover
                                },
                                attrs: { row: "", "align-center": "" },
                                on: {
                                  mouseleave: function($event) {
                                    _vm.hover = false
                                  }
                                }
                              },
                              [
                                _vm.$vuetify.breakpoint.mdAndUp && _vm.hover
                                  ? _c("v-slider", {
                                      staticClass: "ma-0 px-2",
                                      attrs: {
                                        color: "primary",
                                        "hide-details": ""
                                      },
                                      on: { change: _vm.changeVolume },
                                      model: {
                                        value: _vm.volume,
                                        callback: function($$v) {
                                          _vm.volume = $$v
                                        },
                                        expression: "volume"
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-0",
                                    attrs: {
                                      flat: "",
                                      icon: "",
                                      color: "primary"
                                    },
                                    on: {
                                      click: _vm.toggleVolume,
                                      mouseenter: function($event) {
                                        _vm.hover = true
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.volumeIcon))
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "accordion",
                      class: { "is-closed": !_vm.isOpen },
                      attrs: { xs12: "", "text-center": "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _vm.identifier
                                ? _c("favorite-button-media-audio", {
                                    staticStyle: {
                                      opacity: "0",
                                      visibility: "hidden"
                                    },
                                    attrs: { identifier: _vm.identifier }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            [
                              _vm.transcript
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bold text-capitalize",
                                      attrs: {
                                        flat: "",
                                        color: "primary",
                                        small: _vm.$vuetify.breakpoint.xsOnly
                                      },
                                      on: { click: _vm.toggleAccordion }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.isOpen
                                              ? _vm.$t(
                                                  "common.actions.close_transcript"
                                                )
                                              : _vm.$t(
                                                  "common.actions.open_transcript"
                                                )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-right",
                              attrs: { shrink: "" }
                            },
                            [
                              _vm.identifier
                                ? _c("favorite-button-media-audio", {
                                    attrs: { identifier: _vm.identifier }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isOpen
                    ? _c("v-flex", { attrs: { xs12: "", "mt-3": "" } }, [
                        _c("div", { staticClass: "accordion-content" }, [
                          _c("p", {
                            staticClass: "starling-body",
                            staticStyle: { "white-space": "pre-wrap" },
                            domProps: { innerHTML: _vm._s(_vm.transcript) }
                          })
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }