<template>
  <v-layout align-center justify-center>
    <audio ref="player" :src="audioURL" preload="metadata"></audio>

    <v-flex xs11 lg9 xl6>
      <v-container fluid pa-0>
        <v-layout row wrap>
          <v-flex xs12 pa-2 ref="container" class="audio-player" :px-1="$vuetify.breakpoint.xsOnly">
            <v-layout row align-center gap-xs-1 gap-sm-2>
              <v-flex shrink>
                <v-progress-circular v-if="!ready" color="primary" width="3" indeterminate />
                <v-btn v-else flat icon color="primary" class="ma-0" @click="togglePlay">
                  <v-icon>{{ actionIcon }}</v-icon>
                </v-btn>
              </v-flex>
              <v-flex shrink>
                <span style="white-space:nowrap;">
                  {{ currentTime + ' / ' + duration }}
                </span>
              </v-flex>
              <v-flex>
                <v-slider :max="sliderMax" @change="handleProgress" v-model="seekSlider" hide-details class="ma-0 pl-1"></v-slider>
              </v-flex>
              <v-flex shrink>
                <div row align-center :class="{ 'volume-control': $vuetify.breakpoint.mdAndUp && hover }" @mouseleave="hover = false">
                  <v-slider v-if="$vuetify.breakpoint.mdAndUp && hover" color="primary" hide-details class="ma-0 px-2" v-model="volume" @change="changeVolume"></v-slider>
                  <v-btn flat icon color="primary" class="ma-0" @click="toggleVolume" @mouseenter="hover = true">
                    <v-icon>{{ volumeIcon }}</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 text-center class="accordion" :class="{ 'is-closed': !isOpen }">
            <v-layout row wrap>
              <v-flex shrink>
                <favorite-button-media-audio style="opacity:0;visibility: hidden;" v-if="identifier" :identifier="identifier"/>
              </v-flex>
              <v-flex>
                <v-btn flat color="primary" @click="toggleAccordion" class="font-weight-bold text-capitalize"
                      v-if="transcript" :small="$vuetify.breakpoint.xsOnly">
                  {{ isOpen ? $t('common.actions.close_transcript') : $t('common.actions.open_transcript') }}
                </v-btn>
              </v-flex>
              <v-flex shrink class="text-right">
                <favorite-button-media-audio v-if="identifier" :identifier="identifier"/>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 mt-3 v-if="isOpen">
            <div class="accordion-content">
              <p class="starling-body" v-html="transcript" style="white-space: pre-wrap;"></p>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import FavoriteButtonMediaAudio from '@/views/components/utilities/favorite-button-media-audio.vue';

export default {
  name: 'audioPlayer',
  components: { FavoriteButtonMediaAudio },
  props: {
    audioURL: {
      type: String,
      required: false,
    },
    transcript: {
      type: String,
      required: false,
    },
    identifier: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ready: false,
      isOpen: false,
      transcriptOpened: false,
      isPlaying: false,
      seekSlider: 0,
      volume: 75,
      currentTime: '0:00',
      duration: '0:00',
      sliderMax: null,
      hover: false,
    };
  },
  computed: {
    actionIcon() {
      return this.isPlaying ? 'pause' : 'play_arrow';
    },
    volumeIcon() {
      let tempIcon = 'volume_down';
      if (this.volume == 0) {
        tempIcon = 'volume_off';
      } else if (this.volume > 50) {
        tempIcon = 'volume_up';
      }
      return tempIcon;
    },
  },
  mounted() {
    this.updateDuration();
  },
  methods: {
    formatTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
    updateDuration() {
      if (this.audioURL) {
        const player = this.$refs.player;
        player.addEventListener('loadedmetadata', () => {
          this.duration = this.formatTime(player.duration);
          this.sliderMax = player.duration;
        });
        this.ready = true;
      } else {
        this.ready = false;
        this.onError();
      }
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen && !this.transcriptOpened) {
        this.transcriptOpened = true;
      }
    },
    onError() {
      this.$store.commit('setNotification', {
        text: this.$t('common.errors.audio'),
      });
    },
    togglePlay() {
      const player = this.$refs.player;
      if (this.isPlaying) {
        player.pause();
      } else {
        player.play();
        player.addEventListener('timeupdate', () => {
          this.seekSlider = Math.floor(player.currentTime);
          this.currentTime = this.formatTime(this.seekSlider);
          if (player.currentTime == player.duration) {
            this.isPlaying = false;
          }
        });
      }
      this.isPlaying = !this.isPlaying;
    },
    changeVolume() {
      const player = this.$refs.player;
      if (this.volume == 0) {
        player.volume = 0;
        player.muted = true;
      } else {
        player.muted = false;
        player.volume = this.volume / 100;
      }
    },
    toggleVolume() {
      if (this.volume == 0) {
        this.volume = 75;
      } else {
        this.volume = 0;
      }
      this.changeVolume();
    },
    handleProgress() {
      const player = this.$refs.player;
      this.currentTime = this.formatTime(this.seekSlider);
      player.currentTime = this.seekSlider;
    },
  },
};
</script>
<style scoped>
.audio-player {
  background-color: var(--StarlingLighterGrey);
  border-radius: 30px;
  align-content: center;
}

.volume-control {
  background-color: var(--StarlingMediumGrey);
  border-radius: 30px;
  width: 200px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
